import { Controller } from "@hotwired/stimulus";
import { Tab } from "bootstrap";

export default class extends Controller {
  connect() {
    this.initTabs();
    this.handleInitialState();
  }

  initTabs() {
    this.element
      .querySelectorAll('button[data-bs-toggle="tab"]')
      .forEach((tab) => {
        tab.addEventListener("shown.bs.tab", this.updateUrl.bind(this));
      });
    window.addEventListener("popstate", this.handlePopState.bind(this));
  }

  updateUrl(event) {
    const hash = event.target.getAttribute("href");
    const newUrl =
      hash === "#intro"
        ? window.location.pathname
        : `${window.location.pathname}${hash}`;
    history.pushState(null, "", newUrl);
  }

  handlePopState() {
    const hash = window.location.hash || "#home";
    const tab = this.element.querySelector(
      `button[href="${hash}"][data-bs-toggle="tab"]`,
    );
    if (tab) Tab.getOrCreateInstance(tab).show();
  }

  handleInitialState() {
    const hash = window.location.hash;
    if (hash) {
      const tab = this.element.querySelector(
        `button[href="${hash}"][data-bs-toggle="tab"]`,
      );
      if (tab) Tab.getOrCreateInstance(tab).show();
    }
  }
}
