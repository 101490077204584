import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {

  connect() {
    let options = {
      maxItems: 6,
      plugins: ['dropdown_input', 'remove_button'
      ], }
    new TomSelect(this.element, options);
  }
  

}