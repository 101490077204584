import { application } from "./application";
import {
  ChartsController as Charts,
  MapChartsController as MapCharts,
} from "stimulus-echarts";
import TomSelectController from "./tom_select_controller";
import TabsController from "./tabs_controller";

application.register("charts", Charts);
application.register("map-charts", MapCharts);
application.register("tom-select", TomSelectController);
application.register("tabs", TabsController);
